import React, { useEffect } from 'react';
import { makeStyles } from '@fluentui/react-components';
import { ShiningText } from '../shining-text/ShiningText';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    margin: '1em',
    '&>img': {
      width: '95%',
    },
    '&>span, li': {
      textAlign: 'left',
      fontWeight: 500,
    },
    '& a': {
      color: 'rgb(0, 0, 238)',
      textDecorationLine: 'underline',
    },
  },
});

export const JobsTG = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Jobs Search | Telegram';
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <ShiningText text='TELEGRAM POST' />
        <img src='/jobs.png' alt='Jobs' />
        <span>
          We are providing you with all these groups to help you search for your
          dream job and explore vacancies. You can join these groups and
          interact with people who have similar interests. You may also receive
          updates regarding job opportunities in these groups. Additionally, you
          can create your own groups to share job notifications.
        </span>
        <ul>
          {jobs.map(({ label, link }) => (
            <li>
              <a
                onClick={() => {
                  window.location.href = link;
                }}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
        <img src='/jobs_2.png' alt='Jobs' />
        <ul>
          {jobs_2.map(({ label, link }) => (
            <li>
              <a
                onClick={() => {
                  window.location.href = link;
                }}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
        <img src='/jobs_3.png' alt='Jobs' />
        <ul>
          {jobs_3.map(({ label, link }) => (
            <li>
              <a
                onClick={() => {
                  window.location.href = link;
                }}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const jobs = [
  {
    label: 'SDA, FDA,KPSC, PSI',
    link: 'https://telegram.me/karnatakajobs',
  },
  {
    label: 'Real Job Updates',
    link: 'https://chat.whatsapp.com/CMe83G1PK5DHqRZTAILqmS',
  },
  {
    label: 'HR Group India',
    link: 'https://telegram.me/hrgroupindia',
  },
  {
    label: 'Jobs/Internship/Hiring/ updates',
    link: 'https://telegram.me/referredjobs',
  },
  {
    label: 'Crypto Freelancers',
    link: 'https://telegram.me/freelancers_int',
  },
  {
    label: 'Digital Marketing Jobs',
    link: 'https://telegram.me/digitalmarketingjob',
  },
  { label: 'Remotework', link: 'https://telegram.me/remotework' },
  { label: 'Design Work', link: 'https://telegram.me/design_work' },
  { label: 'Itfreelance', link: 'https://telegram.me/itfreelance' },
  { label: 'Writing Jobs', link: 'https://telegram.me/writing_jobs' },
  { label: 'Smm Jobs', link: 'https://telegram.me/smm_jobs' },
  {
    label: 'Freelancehunt Jobs',
    link: 'https://telegram.me/freelancehunt_jobs',
  },
  { label: 'Freelance Today', link: 'https://telegram.me/freelance_today' },
  { label: 'Remoteok', link: 'https://telegram.me/remoteok' },
  { label: 'Job4you', link: 'https://telegram.me/job4you' },
  { label: 'Prog Jobs', link: 'https://telegram.me/prog_jobs' },
  {
    label: 'Marketing Vacancies',
    link: 'https://telegram.me/marketing_vacancies',
  },
  {
    label: 'Employers And Freelancers',
    link: 'https://telegram.me/employers_and_freelancers',
  },
  {
    label: 'Healthcare It Jobs',
    link: 'https://telegram.me/healthcare_it_jobs',
  },
  { label: 'Product Managers', link: 'https://telegram.me/product_managers' },
  {
    label: 'Remote Education Jobs',
    link: 'https://telegram.me/remote_education_jobs',
  },
  {
    label: 'Customer Support Jobs',
    link: 'https://telegram.me/customer_support_jobs',
  },
  {
    label: 'Consulting Remote Jobs',
    link: 'https://telegram.me/consulting_remote_jobs',
  },
];

const jobs_2 = [
  { label: 'Hitech Jobs', link: 'https://telegram.me/hitech_jobs' },
  { label: 'Design Vacancies', link: 'https://telegram.me/design_vacancies' },
  { label: 'Job Telegram', link: 'https://telegram.me/job_telegram' },
  { label: 'Developer Jobs', link: 'https://telegram.me/developer_jobs' },
  { label: 'Remote Developers', link: 'https://telegram.me/remote_developers' },
  { label: 'Graphic Jobs', link: 'https://telegram.me/graphic_jobs' },
  { label: 'Job Board', link: 'https://telegram.me/job_board' },
  { label: 'Content Managers', link: 'https://telegram.me/content_managers' },
  { label: 'Digital Nomads', link: 'https://telegram.me/digital_nomads' },
  { label: 'Web Designers', link: 'https://telegram.me/web_designers' },
  { label: 'Hr Job', link: 'https://telegram.me/hr_job' },
  { label: 'Project Managers', link: 'https://telegram.me/project_managers' },
  { label: 'Seo Vacancies', link: 'https://telegram.me/seo_vacancies' },
  { label: 'Remotepros', link: 'https://telegram.me/remotepros' },
  { label: 'Coders Jobs', link: 'https://telegram.me/coders_jobs' },
  { label: 'Programmer Remote', link: 'https://telegram.me/programmer_remote' },
  { label: 'Native Speakers', link: 'https://telegram.me/native_speakers' },
  { label: 'Uiux Jobs', link: 'https://telegram.me/uiux_jobs' },
  { label: 'Digital Jobs', link: 'https://telegram.me/digital_jobs' },
  { label: 'Remote Tech Jobs', link: 'https://telegram.me/remote_tech_jobs' },
  { label: 'Locale Jobs', link: 'https://telegram.me/locale_jobs' },
  { label: 'Entry Level Jobs', link: 'https://telegram.me/entry_level_jobs' },
  {
    label: 'Mobile Developer Jobs',
    link: 'https://telegram.me/mobile_developer_jobs',
  },
  { label: 'Game Dev Jobs', link: 'https://telegram.me/game_dev_jobs' },
  { label: 'Creative Jobs', link: 'https://telegram.me/creative_jobs' },
  { label: 'Legal Remote Jobs', link: 'https://telegram.me/legal_remote_jobs' },
  {
    label: 'Finance Remote Jobs',
    link: 'https://telegram.me/finance_remote_jobs',
  },
];

const jobs_3 = [
  { label: 'Remote Sales', link: 'https://telegram.me/remote_sales' },
  { label: 'It Jobs', link: 'https://telegram.me/it_jobs' },
  { label: 'Frontend Jobs', link: 'https://telegram.me/frontend_jobs' },
  { label: 'Backend Jobs', link: 'https://telegram.me/backend_jobs' },
  { label: 'Fullstack Jobs', link: 'https://telegram.me/fullstack_jobs' },
  { label: 'Data Science Jobs', link: 'https://telegram.me/data_science_jobs' },
  { label: 'Qa Test Jobs', link: 'https://telegram.me/qa_test_jobs' },
  { label: 'Devops Jobs', link: 'https://telegram.me/devops_jobs' },
  { label: 'Blockchain Jobs', link: 'https://telegram.me/blockchain_jobs' },
  { label: 'Ai Jobs', link: 'https://telegram.me/ai_jobs' },
  {
    label: 'Cybersecurity Jobs',
    link: 'https://telegram.me/cybersecurity_jobs',
  },
];
