import React, { useState, useEffect } from 'react';
import { G1Table } from '../components/table';
import { TableDropdown } from './utils';
import { useFetch, feQueryBuild } from '../api';
import { useId } from '@fluentui/react-components';

export const G1Telegram = () => {
  useEffect(() => {
    document.title = 'Telegram Groups';
  }, []);

  const dropdownId = useId('telegram-category-dropdown');
  const [page, setPage] = useState(1);
  const [category, setcategory] = useState();

  const endpoint = feQueryBuild({
    limit: 10,
    pageNumber: page,
    category,
    // searchQuery: 'friendship',
    // regionCode: undefined,
    baseUrl: 'telegram/index.php',
  });
  const resp = useFetch({ endpoint });
  const { data, loading, error } = resp || {};

  return (
    <div style={{ marginInline: '0.5em' }}>
      <TableDropdown
        dropdownId={dropdownId}
        table='telegram'
        category={category}
        setcategory={setcategory}
      />
      <G1Table
        items={data?.items}
        itemsCount={data?.count}
        page={page}
        updatePageNumber={setPage}
        loading={loading}
        error={error}
      />
    </div>
  );
};
