import React from 'react';
import { useStyles } from './styles';
import {
  Card,
  CardHeader,
  CardPreview,
  Body1,
  Caption1,
} from '@fluentui/react-components';

const data = [
  {
    header: {
      logo: null,
      text: 'Deadpool & Wolverine',
      caption: 'Watch Trailer',
    },
    preview: {
      image: 'https://via.placeholder.com/300',
      altText: 'Group Preview',
    },
    onClick: () =>
      console.log(
        'Clicked Deadpool & Wolverine https://www.youtube.com/watch?v=73_1biulkYk'
      ),
  },
];

export const G1Trending = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {data?.map(({ header, preview, onClick }) => {
        return (
          <MovieCard header={header} preview={preview} onClick={onClick} />
        );
      })}
    </div>
  );
};

const MovieCard = ({ header, preview, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} key={header.text}>
      <CardHeader
        image={
          <img src={header.logo} alt='Group Logo' className={classes.logo} />
        }
        header={
          <Body1>
            <b>{header.text}</b>
          </Body1>
        }
        description={<Caption1>{header.caption}</Caption1>}
      />

      <CardPreview logo={null} onClick={onClick} style={{ cursor: 'pointer' }}>
        <img src={preview.image} alt={preview.altText} />
      </CardPreview>
    </Card>
  );
};
