import React, { useState } from 'react';
import {
  FluentProvider,
  // teamsLightTheme,
  webLightTheme,
  webDarkTheme,
  makeStyles,
} from '@fluentui/react-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { G1Header, G1MainPage, G1Whatsapp, G1Telegram } from './content';
import {
  About,
  Disclaimer,
  Contact,
  PrivacyPolicy,
  Blogs,
  G1Footer,
} from './footer-pages';
import { EarnMoneyWhatsapp } from './content/custom-blogs/EarnMoneyWP';
import { EarnMoneyTelegram } from './content/custom-blogs/EarnMoneyTG';
import { DatingTG } from './content/custom-blogs/DatingTG';
import { G1Trending } from './content/trending';
import { G1Add } from './content/add';
import { isValidTelegramLink, isValidWhatsAppLink } from './global-constants';
import { addToDatabase } from './api';
import { JobsTG } from './content/custom-blogs/JobsTG';

const useStyles = makeStyles({});

export const App = () => {
  const classes = useStyles();

  const [isDark, setIsDark] = useState(false);

  return (
    <FluentProvider theme={isDark ? webDarkTheme : webLightTheme}>
      <BrowserRouter>
        <G1Header
          isDark={isDark}
          changeTheme={() => setIsDark((val) => !val)}
        />
        <Routes>
          <Route exact path='/' element={<G1MainPage />} />
          <Route path='/whatsapp/view' element={<G1Whatsapp />} />
          <Route
            path='/whatsapp/add'
            element={
              <G1Add
                sMedia='whatsapp'
                validator={isValidWhatsAppLink}
                postData={(payload) =>
                  addToDatabase({
                    endpoint: 'whatsapp/index.php',
                    payload,
                  })
                }
              />
            }
          />
          <Route path='/telegram/view' element={<G1Telegram />} />
          <Route
            path='/telegram/add'
            element={
              <G1Add
                sMedia='telegram'
                validator={isValidTelegramLink}
                postData={(payload) =>
                  addToDatabase({
                    endpoint: 'telegram/index.php',
                    payload,
                  })
                }
              />
            }
          />
          <Route path='/about' element={<About />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacy-policies' element={<PrivacyPolicy />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route
            path='/blogs/earn-money/whatsapp'
            element={<EarnMoneyWhatsapp />}
          />
          <Route
            path='/blogs/earn-money/telegram'
            element={<EarnMoneyTelegram />}
          />
          <Route path='/blogs/dating/telegram' element={<DatingTG />} />
          <Route path='/blogs/jobs/telegram' element={<JobsTG />} />
          <Route path='/trending' element={<G1Trending />} />
          <Route
            path='*'
            element={
              <h2 style={{ width: '100%', textAlign: 'center' }}>
                404 Page Not Found
              </h2>
            }
          />
        </Routes>
        <G1Footer />

        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '0.5em',
            width: '100%',
          }}
        >
          © 2025 groupchatlinks.com. All Rights Reserved.
        </span>
      </BrowserRouter>
    </FluentProvider>
  );
};
