import { makeStyles } from '@fluentui/react-components';

export const useStyles = makeStyles({
  root: {
    margin: 'auto',
    textAlign: 'center',
    width: '100%',
  },
  card: {
    margin: '0.5em',
    maxWidth: '300px',
  },
  logo: {
    borderRadius: '5px',
    width: '50px',
  },
});
