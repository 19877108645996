import React, { useState } from 'react';
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  DrawerProps,
  Button,
  makeStyles,
  tokens,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    columnGap: tokens.spacingHorizontalXS,
  },
});

export const G1Drawer = ({
  position = 'end',
  toggle = () => {},
  isOpen = true,
  content = null,
}) => {
  return (
    <div>
      <OverlayDrawer
        position={position}
        open={isOpen}
        onOpenChange={(_, { open }) => toggle()}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance='subtle'
                aria-label='Close'
                icon={<Dismiss24Regular />}
                onClick={() => toggle()}
              />
            }
          >
            Quick Links
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>{content}</DrawerBody>
      </OverlayDrawer>
    </div>
  );
};
