import React from 'react';
import { Dropdown, Option } from '@fluentui/react-components';
import { useFetch } from '../api';

export const TableDropdown = ({
  dropdownId,
  category,
  setcategory,
  table = null,
}) => {
  const catData = useFetch({ endpoint: `category/index.php?sMedia=${table}` });
  const options = catData?.data?.categories;

  return (
    <div style={{ marginBottom: '1em' }}>
      <label htmlFor={dropdownId}>Category</label>
      <br />
      <Dropdown
        clearable
        id={dropdownId}
        onOptionSelect={(_e, { optionValue }) => {
          setcategory(optionValue);
        }}
        value={category}
        disabled={!options}
        style={{ width: '100%', marginTop: '0.25em' }}
      >
        {options?.map((option) => (
          <Option key={option}>{option}</Option>
        ))}
      </Dropdown>
    </div>
  );
};
