export const isValidWhatsAppLink = (url) => {
  const groupPattern = /^https:\/\/chat\.whatsapp\.com\/[A-Za-z0-9]{20,}$/;
  const channelPattern = /^https:\/\/wa\.me\/[0-9]{10,15}$/;
  const officialChannelPattern =
    /^https:\/\/www\.whatsapp\.com\/channel\/[A-Za-z0-9_-]{5,}$/;

  return (
    groupPattern.test(url) ||
    channelPattern.test(url) ||
    officialChannelPattern.test(url)
  );
};

export const isValidTelegramLink = (url) => {
  const groupPattern =
    /^(https:\/\/(t\.me|telegram\.me)\/joinchat\/[A-Za-z0-9_-]{22,})$/;

  const channelPattern = /^(https:\/\/(t\.me|telegram\.me)\/[A-Za-z0-9_]{5,})$/;

  return groupPattern.test(url) || channelPattern.test(url);
};
