import React from 'react';
import './ShiningText.css';

export const ShiningText = ({ text, ...props }) => {
  return (
    <p className='shining-text' {...props}>
      {text}
    </p>
  );
};
