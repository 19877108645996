import React, { useState } from 'react';
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  useArrowNavigationGroup,
} from '@fluentui/react-components';
import { mergeClasses, Spinner } from '@fluentui/react-components';
import { G1Pagination } from '../pagination';
import { useStyles } from './Styles';

const defaultItems = [
  {
    name: 'Friendship and Fun Diaries',
    link: 'https://chat.whatsapp.com/HRY5OHr6ExzHV24EwpWqHj',
  },
];

const defaultColumns = [{ columnKey: 'name', label: 'All Groups' }];

const nameCutter = (value) =>
  value.length > 40 ? value.slice(0, 40) + '...' : value;

export const G1Table = ({
  items = [],
  columns = defaultColumns,
  itemsCount = 0,
  page = 1,
  updatePageNumber = () => {},
  loading = false,
  error = null,
}) => {
  const classes = useStyles();
  const keyboardNavAttr = useArrowNavigationGroup({ axis: 'grid' });

  const HeaderContainer = () => (
    <TableHeader>
      <TableRow className={classes.row}>
        {columns.map(({ columnKey, label }) => (
          <TableHeaderCell key={columnKey} className={classes[columnKey]}>
            {label}
          </TableHeaderCell>
        ))}
      </TableRow>
    </TableHeader>
  );

  const ItemsContainer = () => {
    return items?.length > 0 ? (
      items.map(({ name, link }, index) => (
        <TableRow key={link} className={classes.row}>
          <TableCell tabIndex={0} role='gridcell' className={classes.cell}>
            {nameCutter(name)} -{' '}
            <a
              onClick={() => {
                window.location.href = link;
              }}
              rel='nofollow'
            >
              Join
            </a>
          </TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow key='no-data' className={classes.row}>
        <TableCell
          tabIndex={0}
          role='gridcell'
          className={mergeClasses(classes.cell, classes.noDataRow)}
        >
          {loading ? (
            <Spinner style={{ margin: '11em auto' }} />
          ) : (
            'No data found'
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={classes.container}>
      <Table
        {...keyboardNavAttr}
        role='grid'
        aria-label='Table with grid keyboard navigation'
        className={classes.table}
      >
        <HeaderContainer />
        <TableBody>
          <ItemsContainer />
        </TableBody>
      </Table>
      {itemsCount > 0 && !loading && !error ? (
        <G1Pagination
          activePage={page}
          updatePageNumber={updatePageNumber}
          itemsCount={itemsCount}
        />
      ) : null}
    </div>
  );
};
