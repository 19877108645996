import { makeStyles } from '@fluentui/react-components';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1em',
  },
  card: {
    margin: '0.5em',
    maxWidth: '300px',
    marginInline: 'auto',
  },
  logo: {
    borderRadius: '5px',
    width: '50px',
  },
  footer: {
    justifyContent: 'space-between',

    '& button': {
      fontSize: '0.75em',
      lineHeight: '1em',
      padding: '0.25em 0.5em',

      '& svg': {
        color: 'rgb(36, 161, 222)',
        boxShadow: '1px 1px 1px 1px rgb(36, 161, 222)',
        borderRadius: '5px',
      },
    },
  },
});
