import React from 'react';

import { Body1, Caption1, Button } from '@fluentui/react-components';
import { ArrowReplyRegular, AddCircleFilled } from '@fluentui/react-icons';
import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
} from '@fluentui/react-components';
import { useStyles } from './styles';

export const G1Cards = ({ items = [] }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {items.map(
        ({
          header,
          preview,
          totalGroups,
          onViewGroup = () => {},
          onAddGroup = () => {},
        }) => (
          <Card className={classes.card} key={header.text}>
            <CardHeader
              image={
                <img
                  src={header.logo}
                  alt='Group Logo'
                  className={classes.logo}
                />
              }
              header={
                <Body1>
                  <b>{header.text}</b>
                </Body1>
              }
              description={<Caption1>{totalGroups} - Groups</Caption1>}
            />

            <CardPreview
              logo={null}
              onClick={onViewGroup}
              style={{ cursor: 'pointer' }}
            >
              <img src={preview.image} alt={preview.altText} />
            </CardPreview>

            <CardFooter className={classes.footer}>
              <Button
                onClick={onViewGroup}
                icon={<ArrowReplyRegular />}
                className={classes.footerButton}
              >
                See Active Groups
              </Button>
              <Button
                onClick={onAddGroup}
                icon={<AddCircleFilled />}
                className={classes.footerButton}
              >
                Add New Group
              </Button>
            </CardFooter>
          </Card>
        )
      )}
    </div>
  );
};
