import * as React from 'react';
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogContent,
  Button,
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';

export const G1Modal = ({
  title = '',
  content = '',
  children,
  onClose = () => {},
}) => {
  const [open, setOpen] = React.useState(true);

  const onOpenChange = (event, data) => {
    setOpen(data.open);
    if (!data.open) {
      onClose();
    }
  };
  return (
    <Dialog
      modalType='non-modal'
      open={open}
      onDismiss={onClose}
      onOpenChange={onOpenChange}
    >
      {/* <DialogTrigger disableButtonEnhancement>{children}</DialogTrigger> */}
      <DialogSurface style={{ width: '90%' }} backdrop={true}>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
