import { makeStyles } from '@fluentui/react-components';

export const useFooterStyles = makeStyles({
  root: {
    textAlign: 'center',
    borderTop: '1px solid gray',
    marginTop: '2em',
    height: '100%',

    // position: 'sticky',
    // bottom: '0',
    backgroundColor: 'inherit',
    zIndex: '1',

    display: 'flex',
    flexDirection: 'row',

    '& nav': {
      textAlign: 'left',
      marginRight: '2em',
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },

    '& ul': {
      listStyle: 'none',
      paddingLeft: '1em',
    },
    '& li': {
      lineHeight: '1.5em',
      marginBottom: '0.5em',
    },
  },
});

export const useBlackBox = makeStyles({
  root: {
    textAlign: 'left',
    padding: '1em',
    boxShadow:
      ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    backgroundColor: 'lightgray',
    borderRadius: '5px',
  },
  inlineSpace: {
    margin: '1em',
  },
});
