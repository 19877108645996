import React, { useState } from 'react';
import {
  Input,
  useId,
  Field,
  Dropdown,
  Option,
  makeStyles,
  mergeClasses,
  Button,
} from '@fluentui/react-components';
import { G1MessageBar } from '../../components/message-bar';
import { useFetch } from '../../api';
import { G1Modal } from '../../components/dialog';

const useStyles = makeStyles({
  root: {
    margin: '0.5em',

    '& .fui-Field': {
      marginBottom: '0.5em',
    },
  },
  submitButton: {
    backgroundColor: '#28a745',
    marginTop: '1em',
    width: '100%',
  },
});

export const G1Add = ({
  sMedia = '',
  validator = () => {},
  postData = () => {},
}) => {
  const classes = useStyles();

  const [owner, setOwner] = useState('');
  const [category, setcategory] = useState('');
  const [region, setRegion] = useState('');
  const [regionText, setRegionText] = useState('');
  const [grpName, setGrpName] = useState('');
  const [link, setLink] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [error, setError] = useState(null);

  const catData = useFetch({ endpoint: `category/index.php?sMedia=${sMedia}` });
  const cat = catData?.data?.categories;
  const regionData = useFetch({
    endpoint: `region/index.php?sMedia=${sMedia}`,
  });
  const regions = regionData?.data?.regions.map(({ region, region_code }) => ({
    id: region_code,
    text: region,
  }));

  const categoryId = useId(`${sMedia}-region-dropdown`);
  const regionId = useId(`${sMedia}-category-dropdown`);

  const onSubmit = async () => {
    setError(null);
    if (!owner || !link || !grpName) {
      setError('Please fill all the required fields');
      return;
    }
    if (!validator(link)) {
      console.log('link invalid');
      setError('Group link is not valid');
      return;
    }
    const val = await postData({
      owner,
      category: category || 'All',
      region_code: region || 'GL',
      link,
      group_name: grpName,
      total_members: 0,
    });

    if (val.status === 200) {
      setShowSuccessModal(true);
      return null;
    }

    setError(val.message);
    return null;
  };

  return (
    <div className={classes.root}>
      <h2 style={{ color: 'green' }}>Add new {sMedia} group/channel</h2>
      <Field label='Your Name' required>
        <Input
          placeholder='Please enter your name'
          value={owner}
          onChange={(e) => setOwner(e.target.value)}
        />
      </Field>
      <Field label='Category' required>
        <Dropdown
          id={categoryId}
          onOptionSelect={(_e, { optionValue }) => {
            setcategory(optionValue);
          }}
          value={category}
          disabled={!cat}
        >
          {cat?.map((option) => (
            <Option key={option}>{option}</Option>
          ))}
        </Dropdown>
      </Field>
      <Field label='Region' required>
        <Dropdown
          id={regionId}
          value={regionText}
          selectedOptions={[region]}
          onOptionSelect={(_e, { optionValue, optionText }) => {
            setRegion(optionValue);
            setRegionText(optionText);
          }}
          disabled={!regions}
        >
          {regions?.map(({ id, text }) => (
            <Option key={id} text={text} value={id}>
              {text}
            </Option>
          ))}
        </Dropdown>
      </Field>
      <Field label='Group Name' required>
        <Input
          placeholder='Please enter group name'
          value={grpName}
          onChange={(e) => setGrpName(e.target.value)}
        />
      </Field>
      <Field label={`Group Link (Add only ${sMedia} group link here)`} required>
        <Input
          placeholder='Please enter the group or channel link'
          onChange={(e) => setLink(e.target.value)}
        />
      </Field>
      <Button
        className={classes.submitButton}
        onClick={onSubmit}
        appearance='primary'
      >
        Submit
      </Button>
      {error ? (
        <G1MessageBar content={error} />
      ) : showSuccessModal ? (
        <G1MessageBar content='Group added successfully!' intent='success' />
      ) : null}
      {showSuccessModal ? (
        <G1Modal
          title='Group added successfully!'
          content='Our team will review the group and add it to our website. Stay tuned with us 😊'
          onClose={() => setShowSuccessModal(false)}
        ></G1Modal>
      ) : null}
    </div>
  );
};
