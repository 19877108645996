import { useState, useEffect, useCallback } from 'react';
import { generateSignature, decryptAES128CBC } from './apiUtils';

const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const useFetch = ({ endpoint = '' }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setError(null);
        setLoading(true);
        const signature = await generateSignature();
        const response = await fetch(
          `${REACT_APP_BASE_API_URL}/${endpoint}&code=${signature}&timestamp=${Date.now()}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // 'Cache-Control': 'no-store, no-cache',
            },
          }
        );
        const data = await response.json();

        if (!data.iv) {
          setData(data);
        } else {
          // const encryptedData = data;
        }
      } catch (error) {
        console.log('error_ ', error);
        setError('Something went wrong...');
      } finally {
        setLoading(false);
      }
    };
    fetchTasks();
  }, [endpoint]);

  return { data, error, loading };
};

export const addToDatabase = async ({ endpoint = '', payload = {} }) => {
  try {
    const signature = await generateSignature();
    const response = await fetch(
      `${REACT_APP_BASE_API_URL}/${endpoint}&code=${signature}&timestamp=${Date.now()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Cache-Control': 'no-store, no-cache',
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    return { ...res, status: response.status };
  } catch (error) {
    return { message: 'Something went wrong...', status: 500 };
  }
};

export const PER_PAGE_LIMIT = 10;

export const DEFAULT_CATEGORY = 'All';
export const DEFAULT_REGION_CODE = 'GL';

export const feQueryBuild = ({
  limit = PER_PAGE_LIMIT,
  pageNumber = 0,
  category = undefined,
  searchQuery = '',
  regionCode = undefined,
  baseUrl = '',
}) => {
  let q = `${baseUrl}?limit=${limit}&page=${pageNumber}`;

  if (category) {
    q += `&category=${category}`;
  }

  if (searchQuery) {
    q += `&search_query=${searchQuery}`;
  }

  if (regionCode) {
    q += `&region_code=${regionCode}`;
  }

  return q;
};

/*
export const useDelete = async ({ id }) => {
  const response = await fetch(`${REACT_APP_BASE_API_URL}/api.php?id=${id}`, {
    method: "DELETE",
  });
  const data = await response.json();
  fetchTasks();
};
*/
