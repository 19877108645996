import React, { useEffect } from 'react';
import { makeStyles } from '@fluentui/react-components';
import { ShiningText } from '../shining-text/ShiningText';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    margin: '1em',
    '&>img': {
      width: '95%',
    },
    '&>span, li': {
      textAlign: 'left',
      fontWeight: 500,
    },
    '& a': {
      color: 'rgb(0, 0, 238)',
      textDecorationLine: 'underline',
    },
  },
});

export const DatingTG = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Telegram Dating groups';
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <ShiningText text='TELEGRAM POST' />
        <img src='/earn_7.png' alt='Money cash' />
        <span>
          Meet new people and spark a conversation. The world is big and full of
          cheerful people. You can chat with strangers and get to know them.
          These groups are very popular and people find them quite interesting.
        </span>
        <ul>
          {dating.map(({ label, link }) => (
            <li>
              <a
                onClick={() => {
                  window.location.href = link;
                }}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
        <img src='/earn_9.png' alt='Dating Pictures' />
        <ul>
          {dating2.map(({ label, link }) => (
            <li>
              <a
                onClick={() => {
                  window.location.href = link;
                }}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
        <img src='/earn_8.png' alt='Friends chatting' />
        <span>
          You can make new friends on these groups. Just join these groups and
          start a conversation.
        </span>
        <ul>
          {friendship.map(({ label, link }) => (
            <li>
              <a
                onClick={() => {
                  window.location.href = link;
                }}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
        <img src='/earn_10.png' alt='Friends chatting' />
        <ul>
          {friendship2.map(({ label, link }) => (
            <li>
              <a
                onClick={() => {
                  window.location.href = link;
                }}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const dating = [
  {
    label: 'FRIENDS',
    link: 'https://telegram.me/NewFriendChat',
  },
  {
    label: 'Dating Chat',
    link: 'https://t.me/dating911',
  },
  {
    label: '❤️❤️ LOVE & MARRIAGE ❤️❤️',
    link: 'https://telegram.me/usadateworld',
  },
  {
    label: 'Super dating',
    link: 'https://t.me/joinchat/Jkf1W06rs0_pMXdH4Uwq3g',
  },
  {
    label: 'Real Women',
    link: 'https://telegram.me/RealWomen4k',
  },
  {
    label: 'Gentle Women',
    link: 'https://telegram.me/gentlewomensayings',
  },
  { label: 'Girls group', link: 'https://t.me/girlshotgroup' },
  {
    label: 'We love to chat',
    link: 'https://t.me/joinchat/Jkf1W0UyA0JZUbVc63_Wbg',
  },
  {
    label: '♥️ International Friends ♥️',
    link: 'https://telegram.me/best_chat_group',
  },
  {
    label: 'Marriage ',
    link: 'https://t.me/SunnahMarriages',
  },
  {
    label: 'Turkey WOMEN’S WEAR',
    link: 'https://telegram.me/WomenClothingWholesale',
  },
  {
    label: 'Pickup lines 🐬',
    link: 'https://telegram.me/Pickup_LineTM',
  },
];

const dating2 = [
  {
    label: 'Beautiful Women',
    link: 'https://t.me/mote_girls',
  },
  {
    label: 'Perfectmate dating',
    link: 'https://telegram.me/perfectmatedating',
  },
  {
    label: 'We love to chat',
    link: 'https://telegram.me/perfectmatematch',
  },
  { label: 'PLATFORM of Old Age Dating', link: 'https://t.me/datingPlatform' },
  {
    label: 'Older men and women',
    link: 'https://t.me/+v4umVhG6t5cyODg0',
  },
  {
    label: 'USA Widow',
    link: 'https://jointelegramlink.com/usa-telegram-group-link/',
  },
  {
    label: 'English Dating Group',
    link: 'https://t.me/joinchat/EnD9SkO9Awougo5VjknbrQ',
  },
  {
    label: 'Women Fashion Store	',
    link: 'https://telegram.me/WomenFashionStore',
  },
  {
    label: 'Man vs Women Fashion ',
    link: 'https://t.me/BoysVsGirlsFashion',
  },
  {
    label: 'Couples ',
    link: 'https://t.me/Love_Couples_images_girls_boys',
  },
  {
    label: 'Indian Bhabhi',
    link: 'https://t.me/+Qvjcj3R2i9NhMDU1',
  },
  {
    label: 'Match UK & Ireland',
    link: 'https://telegram.me/matchv',
  },
];

const friendship = [
  { label: 'Sarah & Friends', link: 'https://t.me/sarahandfriends' },
  { label: 'Friendly & Salesman', link: 'https://t.me/grouprope' },
  {
    label: 'International Online Friends	',
    link: 'https://t.me/befriendlywitheveryone',
  },
  { label: "John Dee's Magic Circle", link: 'https://t.me/johndeezgroup' },
  { label: 'Friends', link: 'https://t.me/Tamil_Friends_Chatting_Group12' },
  {
    label: 'Private group Noob Friends',
    link: 'https://t.me/PrivateGroupNoobFriendly',
  },
  {
    label: 'UNITED GLOBAL FRIENDS GROUP',
    link: 'https://t.me/united_global_friends',
  },
  {
    label: '✾♡𝑾𝒐𝒓𝒍𝒅 𝒐𝒇 𝒇𝒆𝒊𝒆𝒏𝒅𝒔♡✾',
    link: 'https://t.me/learningenglishtrump',
  },
  {
    label: '♥️ International Friends ♥️',
    link: 'https://telegram.me/best_chat_group',
  },
  {
    label: '🇮🇳Indian Group🇮🇳',
    link: 'https://telegram.me/Indian_Group_Chat',
  },
  {
    label: 'International friends club',
    link: 'https://t.me/internationalss',
  },
  {
    label: 'AI Friends',
    link: 'https://t.me/+girGftc0xVBhOTE0',
  },
  {
    label: 'Friends of Pawan',
    link: 'https://telegram.me/friendsofpawan',
  },
  { label: 'Friendship Girls ❤️ Boys', link: 'https://t.me/IndiaGirlsandBoys' },
  {
    label: 'USA Friends',
    link: 'https://jointelegramlink.com/usa-telegram-group-link',
  },
  {
    label: 'Real Friendship',
    link: 'https://jointelegramlink.com/gay-telegram-group-links/',
  },
  {
    label: '🌟Friends🌟',
    link: 'https://telegram.me/Real_friends',
  },
  { label: 'FRIENDSHIP_BGM', link: 'https://t.me/friendship_bgm' },
  { label: 'FRIENDS ONLINE', link: 'https://t.me/hhhhhhhhhhhhh888999' },
];

const friendship2 = [
  {
    label: 'Friendz',
    link: 'https://telegram.me/FriendzOfficialChannel',
  },
  {
    label: 'Friends of Truth',
    link: 'https://t.me/friendsoftruth',
  },
  {
    label: ';🥰Korean Japanese Friends group	',
    link: 'https://t.me/Korean_japnese_friends',
  },
  { label: 'USA Girls Friendship', link: 'https://t.me/usagirlsfriendship' },
  {
    label: 'Chatting Friendship',
    link: 'https://t.me/Single_Girls_Chatting_Friendship',
  },
  {
    label: 'USA🇺🇸AND UK🇬🇧FRIENDS',
    link: 'https://t.me/USA_AND_UK_FRIENDS',
  },
  {
    label: 'International friends club',
    link: 'https://telegram.me/internationalss',
  },
  {
    label: 'FRIENDSHIP WORLDWIDE',
    link: 'https://telegram.me/makenewfriendsalways',
  },
  {
    label: 'International friends club',
    link: 'https://t.me/international_english_chattings',
  },
  {
    label: 'English Chatting – international group',
    link: 'https://t.me/American_English_Chatting',
  },
  {
    label: '𝗙𝗥𝗜𝗘𝗡𝗗𝗦 𝗖𝗛𝗔𝗧𝗧𝗜𝗡𝗚 𝗚𝗥𝗢𝗨𝗣	',
    link: 'https://t.me/koreanenglishfriends',
  },
];
