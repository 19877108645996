import React, { useEffect } from 'react';
import { G1Cards } from '../components/cards';
import { useFetch } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';

const landingPageCards = ({
  total_telegram_entries,
  total_whatsapp_entries,
  goToWhatsappView,
  goToTelegramView,
  addWhatsapp,
  addTelegram,
}) => [
  {
    header: {
      logo: 'logo_whatsapp.png',
      text: 'WhatsApp Groups',
    },
    preview: {
      image: 'preview_whatsapp.png',
      altText: 'whatsapp Group Collection PNG',
    },
    totalGroups: total_whatsapp_entries,
    onViewGroup: goToWhatsappView,
    onAddGroup: addWhatsapp,
  },
  {
    header: {
      logo: 'logo_telegram.png',
      text: 'Telegram Groups',
    },
    preview: {
      image: 'preview_telegram.png',
      altText: 'Telegram Group Collection PNG',
    },
    totalGroups: total_telegram_entries,
    onViewGroup: goToTelegramView,
    onAddGroup: addTelegram,
  },
];

export const G1MainPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Group Chat Links | Whatsapp | Telegram | Online Chat';
  }, []);

  const resp = useFetch({ endpoint: 'count/index.php?val=1' });
  const { total_telegram_entries, total_whatsapp_entries } = resp.data || {};

  return (
    <G1Cards
      items={landingPageCards({
        total_telegram_entries,
        total_whatsapp_entries,
        goToWhatsappView: () => navigate('/whatsapp/view'),
        addWhatsapp: () => navigate('/whatsapp/add'),
        goToTelegramView: () => navigate('/telegram/view'),
        addTelegram: () => navigate('/telegram/add'),
      })}
    />
  );
};
