import { makeStyles } from '@fluentui/react-components';

export const useStyles = makeStyles({
  container: {},
  table: {
    fontWeight: '500',
  },
  row: {
    height: '1.25em',
    lineHeight: '1.25em',
    minWidth: 'none !important',
  },
  cell: {
    height: '100%',
    lineHeight: '2em',
    minWidth: 'none !important',
    '& a': {
      color: 'rgb(36, 161, 222)',
      textDecorationLine: 'underline',
    },
  },
  noDataRow: {
    textAlign: 'center',
  },
});
