import React, { useState, useEffect } from 'react';
import { G1Table } from '../components/table';
import { useFetch, feQueryBuild } from '../api';
import { Dropdown, Option, useId } from '@fluentui/react-components';
import { TableDropdown } from './utils';

export const G1Whatsapp = () => {
  const dropdownId = useId('whatsapp-category-dropdown');
  const [page, setPage] = useState(1);
  const [category, setcategory] = useState();

  const endpoint = feQueryBuild({
    limit: 10,
    pageNumber: page,
    category,
    // searchQuery: 'friendship',
    // regionCode: undefined,
    baseUrl: 'whatsapp/index.php',
  });

  useEffect(() => {
    document.title = 'Whatsapp Groups';
  }, []);

  const resp = useFetch({ endpoint });
  const { data, loading, error } = resp || {};

  return (
    <div style={{ marginInline: '0.5em' }}>
      <TableDropdown
        dropdownId={dropdownId}
        table='whatsapp'
        category={category}
        setcategory={setcategory}
      />
      <G1Table
        items={data?.items}
        itemsCount={data?.count}
        page={page}
        updatePageNumber={setPage}
        loading={loading}
        error={error}
      />
    </div>
  );
};
