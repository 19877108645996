import sha256 from 'js-sha256';

const SECRET_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

export const generateSignature = (message = '', secretKey = SECRET_KEY) =>
  sha256.hmac(secretKey, message);

async function decryptAES256GCM(encryptedData, key) {
  const { ciphertext, iv, tag } = encryptedData;

  try {
    // Convert base64-encoded strings to ArrayBuffer
    const ciphertextBuffer = Uint8Array.from(atob(ciphertext), (c) =>
      c.charCodeAt(0)
    );
    const ivBuffer = Uint8Array.from(atob(iv), (c) => c.charCodeAt(0));
    const tagBuffer = Uint8Array.from(atob(tag), (c) => c.charCodeAt(0));

    // Combine the ciphertext and tag into one buffer (Web Crypto expects the tag to be appended to the ciphertext)
    const dataWithTag = new Uint8Array(
      ciphertextBuffer.length + tagBuffer.length
    );
    dataWithTag.set(ciphertextBuffer, 0);
    dataWithTag.set(tagBuffer, ciphertextBuffer.length);

    console.log('Ciphertext Buffer:', ciphertextBuffer);
    console.log('IV Buffer:', ivBuffer);
    console.log('Tag Buffer:', tagBuffer);
    console.log('Data with Tag:', dataWithTag);

    // Decrypt the data
    const decryptedBuffer = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: ivBuffer, // Initialization vector
        tagLength: 128, // AES-GCM tag length in bits
      },
      key, // The AES key used for decryption
      dataWithTag // The combined ciphertext and tag buffer
    );

    // Convert the decrypted buffer to a string (assuming it's JSON)
    const decryptedText = new TextDecoder().decode(decryptedBuffer);

    // Parse and return the decrypted JSON
    return JSON.parse(decryptedText);
  } catch (error) {
    console.error('Decryption failed:', error);
    return null;
  }
}

export const decryptAES128CBC = async ({ data, iv }) => {
  let enc = new TextEncoder();
  const keyData = SECRET_KEY;
  const key = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(keyData),
    { name: 'AES-GCM' },
    false,
    ['decrypt']
  );

  const encryptedData = {
    ciphertext: data,
    iv,
    tag: '',
  };

  // 3. Decrypt the data
  const decryptedData = await decryptAES256GCM(encryptedData, key);
  console.log(decryptedData);
};
