import React from 'react';
import { Link, BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import { useFooterStyles, useBlackBox } from './styles';
import { mergeClasses } from '@fluentui/react-components';
import { G1Accordion } from '../components/accordion';

const row1 = [
  { label: 'About', link: '/about' },
  { label: 'Contact', link: '/contact' },
  { label: 'Privacy Policy', link: '/privacy-policies' },
];
const row2 = [
  { label: 'Disclaimer', link: '/disclaimer' },
  { label: 'Blogs', link: '/blogs' },
];

const G1Footer = () => {
  const classes = useFooterStyles();

  return (
    <>
      <div className={classes.root}>
        <nav>
          <ul>
            {row1.map(({ label, link }) => (
              <li key={label}>
                <Link to={link}>{label}</Link>
              </li>
            ))}
          </ul>
        </nav>
        <nav>
          <ul>
            {row2.map(({ label, link }) => (
              <li key={label}>
                <Link to={link}>{label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

const About = () => {
  const classes = useBlackBox();
  return (
    <div className={mergeClasses(classes.root, classes.inlineSpace)}>
      <h4>About Us:</h4>
      We provide group links of several social media platforms. These groups are
      public and anyone can join them. These groups can be related to fashion,
      tourism, finance, technology, engineering, university entrance
      examination, Govt. Jobs, private jobs, PSU Jobs, news and media,
      photography, business, share market, marketing etc...
    </div>
  );
};

const Contact = () => {
  const classes = useBlackBox();
  return (
    <div className={mergeClasses(classes.root, classes.inlineSpace)}>
      <h4>Our Contact:</h4>
      Thank you for visiting our website. Please write to
      helpdesk@groupchatlinks.com for any queries.
    </div>
  );
};

const Disclaimer = () => {
  const classes = useBlackBox();
  return (
    <div className={mergeClasses(classes.root, classes.inlineSpace)}>
      <b>Disclaimer: </b> We provide group links of various social media
      platforms so that people can pursue their interests and hobbies. We do not
      have any direct or indirect connection with any of the groups. We are not
      responsible for any kind of harm/damage/fraud. All the groups on our
      website are public and are not owned/controlled by us.
    </div>
  );
};

const PrivacyPolicy = () => {
  const classes = useBlackBox();

  const data = [
    {
      heading: 'Privacy Policy',
      text: 'All the links available on our website are public and are posted by people across the globe. We do not own any social media group information displayed on our website. The links are provided to help people pursue their interest such as music, tourism, finance, education etc. This website is not affiliated with any social media platform such as Whatsapp or Telegram. We operate independently.',
    },
    {
      heading: 'Information we collect',
      text: 'The personal information that you are asked to provide, and the choices you have to accept or deny, are collected from you directly. When you contact us by email or any other method, the information gets saved in the respective communication method.',
    },
    {
      heading: 'Our advertising partners',
      text: "Our website's privacy policy does not apply to other advertisers or websites. It is recommended to consult the Privacy Policies of these third-party ad servers for detailed information.",
    },
    {
      heading: 'Remove data from our website',
      text: (
        <>
          Please contact us directly if you want to remove your personal
          information from our website. The contact details are provided in the{' '}
          <a href='/contact'>contact</a> section. However, we rarely collect any
          visitor's personal information
        </>
      ),
    },
    {
      heading: 'Consent',
      text: 'By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.',
    },
  ];
  return (
    <div className={classes.inlineSpace}>
      {data.map(({ heading, text }) => (
        <div key={heading}>
          <h4>{heading}</h4>
          <div className={classes.root}>{text}</div>
          <br />
        </div>
      ))}
    </div>
  );
};

const Blogs = () => {
  const stepsToAddGroups = (
    <>
      <b>Step 1: </b>Click on below link <br />
      <b>Step 2: </b>Select social media platform
      <ul style={{ listStyleType: 'square', marginLeft: '1.5em' }}>
        <li>WhatsApp</li>
        <li>Telegram</li>
        {/* <div>- Facebook</div>
            <div>- TikTok</div>
            <div>- Instagram</div>
            <div>- Twitter</div>
            <div>- YouTube</div>
            <div>- Snapchat</div>
            <div>- Reddit</div>
            <div>- Discord</div> */}
        <li>Others</li>
      </ul>
      <b>Step 3: </b>Fill other details <br />
      <b>Step 4: </b>Click on submit <br />
      <br />
    </>
  );

  const data = [
    {
      value: '1',
      header: 'How to add your social media group?',
      content: stepsToAddGroups,
    },
    {
      value: '2',
      header: 'How to find relevant group links?',
      content:
        'Just go to home page and click on respective social media platform such as whatsapp or telegram. On clicking, you will be redirected to a new page where group information will be displayed.',
    },
    {
      value: '3',
      header: 'How to earn money on online groups?',
      content: (
        <>
          Click below to read the post on how to earn money on online groups.
          You can also join our telegram or whatsapp group to get latest updates
          on how to earn money online.
          <ul style={{ paddingInline: '1em' }}>
            <li>
              <a href='/blogs/earn-money/whatsapp'>Earn money on whatsapp</a>
            </li>
            <li>
              <a href='/blogs/earn-money/telegram'>Earn money on telegram</a>
            </li>
          </ul>
        </>
      ),
    },
  ];
  return <G1Accordion data={data} />;
};

export { About, Disclaimer, G1Footer, Contact, PrivacyPolicy, Blogs };
