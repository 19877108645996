import React from 'react';
import {
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  MessageBarIntent,
  Link,
  makeStyles,
} from '@fluentui/react-components';

const useClasses = makeStyles({
  container: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',

    '& .fui-MessageBar': {
      padding: '0.5em',
    },

    '& .fui-MessageBarBody': {
      textWrap: 'wrap',
    },
  },
});
const intents = ['info', 'warning', 'error', 'success'];

export const G1MessageBar = ({ content, intent = 'error' }) => {
  const classes = useClasses();

  return (
    <div className={classes.container}>
      <MessageBar key={intent} intent={intent}>
        <MessageBarBody>{content}</MessageBarBody>
      </MessageBar>
    </div>
  );
};
