import React from 'react';

import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from '@fluentui/react-components';
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  content: {
    marginInline: '2em',
  },
});

export const G1Accordion = ({ data = [] }) => {
  const classes = useStyles();

  return (
    <Accordion multiple collapsible>
      <br />
      {data.map(({ value, header, content }) => (
        <>
          <AccordionItem value={value}>
            <AccordionHeader style={{ backgroundColor: '#CCCCCC' }}>
              {header}
            </AccordionHeader>
            <AccordionPanel>
              <br />
              <div className={classes.content}>{content}</div>
            </AccordionPanel>
          </AccordionItem>
          <br />
        </>
      ))}
    </Accordion>
  );
};
